async function readServicesJson() {
  try {
    const response = await fetch("/services.json");
    if (!response.ok) {
      throw new Error("Failed to fetch services");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(`Error fetching services: ${error}`);
  }
}

function createNavBarDropdown(services, category) {
  const listItem = document.getElementById(category);
  const dropdownMenu = document.createElement("ul");
  let hoverTimer;

  if (!listItem) return;

  dropdownMenu.classList.add("dropdown-menu");
  dropdownMenu.classList.add("hidden");

  const publishedServices = services.filter(
    (service) => service.status === "PUBLISHED"
  );
  const numServicesMedia = publishedServices.filter(
    (service) =>
      category === "media" && service.serviceMetadata.category === "media"
  ).length;
  const numServicesAll = publishedServices.length;
  const windowWidth = window.innerWidth;

  let numColumns;

  if (windowWidth >= 950 && windowWidth <= 1350) {
    numColumns = 2;
  } else {
    numColumns =
      category === "media"
        ? Math.min(numServicesMedia, 3)
        : Math.min(numServicesAll, 3);
  }

  if (windowWidth < 1024) {
    dropdownMenu.classList.add("small");
  } else {
    dropdownMenu.classList.remove("small");
  }

  publishedServices.forEach((service) => {
    if (
      (category === "media" && service.serviceMetadata.category === "media") ||
      (service.status === "PUBLISHED" && category !== "media")
    ) {
      const menuItem = document.createElement("li");
      const link = document.createElement("a");
      link.target = "_blank";
      link.textContent = service.serviceMetadata.title;
      link.href = `https://app.osaas.io/browse/${service.serviceId}`;
      menuItem.appendChild(link);
      dropdownMenu.appendChild(menuItem);
    }
  });

  listItem?.parentNode?.appendChild(dropdownMenu);
  listItem.classList.add("parent-relative");

  const dropdownLeft =
    listItem.offsetLeft - (dropdownMenu.offsetWidth - listItem.offsetWidth) / 2;
  dropdownMenu.style.left = dropdownLeft + "px";
  dropdownMenu.style.top = listItem.offsetTop + listItem.offsetHeight + "px";

  const toggleDropdownDisplay = () => {
    clearTimeout(hoverTimer);
    document
      .querySelectorAll<HTMLUListElement>(".dropdown-menu")
      .forEach((menu) => {
        if (menu !== dropdownMenu) {
          menu.classList.add("hidden");
        }
      });
    dropdownMenu.classList.remove("hidden");
    dropdownMenu.classList.add("grid-display");
  };

  const hideDropdown = () => {
    hoverTimer = setTimeout(() => {
      dropdownMenu.classList.remove("grid-display")
      dropdownMenu.classList.add("hidden");
    }, 500);
  };

  listItem.addEventListener("mouseenter", () => {
    toggleDropdownDisplay();
    clearTimeout(hoverTimer);
  });

  dropdownMenu.addEventListener("mouseenter", () => {
    clearTimeout(hoverTimer);
  });

  dropdownMenu.addEventListener("mouseleave", () => {
    hideDropdown();
  });

  listItem.addEventListener("mouseleave", () => {
    hideDropdown();
  });
}

async function init() {
  try {
    const services = await readServicesJson();
    createNavBarDropdown(services, "all");
    createNavBarDropdown(services, "media");
  } catch (error) {
    console.error(error.message);
  }
}

init();

document.addEventListener("DOMContentLoaded", async () => {
  async function showServices(category) {
    const services = await readServicesJson();
    const publishedServices = services.filter(
      (service) => service.status === "PUBLISHED"
    );

    clearServiceLists();

    if (category && category === "media") {
      const mediaServices = publishedServices.filter(
        (service) => service.serviceMetadata.category === "media"
      );
      displayServices(mediaServices, "mediaServiceList");
    } else {
      displayServices(publishedServices, "allServiceList");
    }
  }

  function displayServices(services, containerId) {
    const serviceList = document.getElementById(containerId);

    services.forEach((service) => {
      const serviceItem = document.createElement("a");
      serviceItem.href = `https://app.osaas.io/browse/${service.serviceId}`;
      serviceItem.textContent = service.serviceMetadata.title;
      serviceList?.appendChild(serviceItem);
    });
  }

  function clearServiceLists() {
    const allServicesList = document.getElementById("allServicesList");
    const mediaServicesList = document.getElementById("mediaServicesList");
    if (allServicesList) {
      allServicesList.innerHTML = "";
    }
    if (mediaServicesList) {
      mediaServicesList.innerHTML = "";
    }
  }

  function toggleDropdown(dropdown) {
    dropdown.classList.toggle("open");
  }

  document.querySelectorAll(".hamburger-icon").forEach((btn) => {
    btn.addEventListener("click", function (event) {
      const dropdown = this.closest(".hamburger");
      toggleDropdown(dropdown);
    });
  });

  const allServices = document.getElementById("allServices");
  allServices?.addEventListener("click", function (event) {
    event.stopPropagation();
    showServices("");
  });

  const mediaServices = document.getElementById("mediaServices");
  mediaServices?.addEventListener("click", function (event) {
    event.preventDefault();
    event.stopPropagation();
    showServices("media");
  });

  document.addEventListener("click", (event) => {
    const dropdowns = document.querySelectorAll(".hamburger");
    dropdowns.forEach((dropdown) => {
      if (!dropdown.contains(event.target as Node)) {
        dropdown.classList.remove("open");
        clearServiceLists();
      }
    });
  });
});
